import { Link } from "react-router-dom";
import logoWhite from "../static/logo-white.png";
import { useState } from "react";
import { Branch } from "../utils/enum";
const signalIcon = "./static/icons/signal.svg";
const instagramIcon = "./static/icons/instagram.svg";
const whatsappIcon = "./static/icons/whatsapp.svg";

const Footer = () => {
  const [activeTab, setActiveTab] = useState(Branch.KWAI_FONG);

  return (
    <footer className="flex flex-col justify-between bg-[#ACA394] pb-6 pt-10 text-white xl:h-[600px] xl:pt-8">
      <div className="mx-auto flex w-full max-w-[1600px] flex-col justify-between gap-10 px-10 md:px-20 xl:flex-row xl:gap-64">
        <div className="flex flex-1 justify-center">
          {" "}
          <img
            src={logoWhite}
            className="h-auto w-[100px] xl:h-48 xl:w-auto"
            alt="葵芳 中醫 針灸 正骨 拔罐 刮痧 濕疹 Covid-19 視像診症 遙距診症 高低膊 脊柱側彎 徐宇聖 鄧敏賢 聖賢堂 logo HK "
          ></img>
        </div>
        <div className="flex-1 shrink-0 flex-col items-center justify-center text-sm xl:text-base">
          <p className="text-center">應診服務時間</p>
          <p className="mt-4 text-center">星期一至日: 10:00 - 19:00</p>
          <p className="mt-4 text-center">午休: 14:00 - 15:00</p>
          <p className="mt-4 text-center">公眾假期: 將另行通知</p>
        </div>
        <div className="flex-1 flex-col justify-center text-sm xl:text-base">
          <p className="text-center" style={{ borderBottom: "dashed" }}>
            聯絡資料
          </p>

          <div className="flex-1 flex-col justify-center text-sm xl:text-base">
            <div className="mb-6 flex justify-center gap-8">
              <button
                className={`pb-1 ${
                  activeTab === Branch.KWAI_FONG ? "border-b-2" : ""
                }`}
                onClick={() => setActiveTab(Branch.KWAI_FONG)}
              >
                葵芳
              </button>
              <button
                className={`pb-1 ${
                  activeTab === Branch.YUEN_LONG ? "border-b-2" : ""
                }`}
                onClick={() => setActiveTab(Branch.YUEN_LONG)}
              >
                元朗
              </button>
              <button
                className={`pb-1 ${
                  activeTab === Branch.KWUN_TONG ? "border-b-2" : ""
                }`}
                onClick={() => setActiveTab(Branch.KWUN_TONG)}
              >
                觀塘
              </button>
              <button
                className={`pb-1 ${
                  activeTab === Branch.UK_VIDEO ? "border-b-2" : ""
                }`}
                onClick={() => setActiveTab(Branch.UK_VIDEO)}
              >
                視像診症
              </button>
            </div>
            {activeTab === Branch.KWAI_FONG && (
              <div>
                <div className="flex justify-center gap-4">
                  <a
                    href="https://www.google.com/maps?q=%E8%91%B5%E6%B6%8C%E8%88%88%E8%8A%B3%E8%B7%AF223%E8%99%9F%E6%96%B0%E9%83%BD%E6%9C%83%E5%BB%A3%E5%A0%B4%E7%AC%AC%E4%B8%80%E5%BA%A7%E5%BF%A0%E9%86%AB%E8%A8%BA%E6%89%80+%E8%81%96%E8%B3%A2%E5%A0%8221%E6%A8%932113-2114%E5%AE%A4"
                    role="button"
                  >
                    新界葵芳興芳路223號新都會廣場一期21樓
                    <p>2113-2114室</p>
                  </a>
                </div>
                <div className="mt-6 flex w-[100%] justify-center gap-4 lg:justify-start">
                  <div className="">
                    <p>23332025, 64491632 (10:00-19:00)</p>
                  </div>
                </div>
                <p className="mt-2 flex justify-center">
                  <button
                    onClick={() => window.open("https://wa.me/85264491632")}
                  >
                    <img
                      src={whatsappIcon}
                      alt="WhatsApp"
                      width={34}
                      height={34}
                    />
                  </button>
                  <button
                    className="ml-4"
                    onClick={() =>
                      window.open("https://signal.me/#p/+85264491632")
                    }
                  >
                    <img src={signalIcon} alt="Signal" width={34} height={34} />
                  </button>
                  <button
                    className="ml-4"
                    onClick={() =>
                      window.open("https://www.instagram.com/syt.cmp/")
                    }
                  >
                    <img
                      src={instagramIcon}
                      alt="Instagram"
                      width={34}
                      height={34}
                    />
                  </button>
                </p>
              </div>
            )}
            {activeTab === Branch.YUEN_LONG && (
              <div>
                <div className="flex justify-center gap-4">
                  <a href="https://g.co/kgs/jnVDmRd" role="button">
                    元朗青山公路元朗段六十五號豪景商業大廈
                    <p>二十樓全層</p>
                  </a>
                </div>
                <div className="mt-6 flex w-[100%] justify-center gap-4 lg:justify-start">
                  <div className="">
                    <p>24683668, 63811939 (10:00-19:00)</p>
                  </div>
                </div>
                <p className="mt-2 flex justify-center">
                  <button
                    onClick={() => window.open("https://wa.me/85263811939")}
                  >
                    <img
                      src={whatsappIcon}
                      alt="WhatsApp"
                      width={34}
                      height={34}
                    />
                  </button>
                  <button
                    className="ml-4"
                    onClick={() =>
                      window.open("https://signal.me/#p/+85263811939")
                    }
                  >
                    <img src={signalIcon} alt="Signal" width={34} height={34} />
                  </button>
                </p>
              </div>
            )}

            {activeTab === Branch.KWUN_TONG && (
              <div>
                <div className="flex justify-center gap-4">
                  <a href="https://g.co/kgs/q2SDS9X" role="button">
                    觀塘觀塘道418號創紀之城5期-東亞銀行中心
                    <p>16樓08室(APM樓上)</p>
                  </a>
                </div>
                <div className="mt-6 flex w-[100%] justify-center gap-4 lg:justify-start">
                  <div className="">
                    <p>23236992, 61540243 (10:00-19:00)</p>
                  </div>
                </div>
                <p className="mt-2 flex justify-center">
                  <button
                    onClick={() => window.open("https://wa.me/85261540243")}
                  >
                    <img
                      src={whatsappIcon}
                      alt="WhatsApp"
                      width={34}
                      height={34}
                    />
                  </button>
                  <button
                    className="ml-4"
                    onClick={() =>
                      window.open("https://signal.me/#p/+85261540243")
                    }
                  >
                    <img src={signalIcon} alt="Signal" width={34} height={34} />
                  </button>
                  <button
                    className="ml-4"
                    onClick={() =>
                      window.open("https://www.instagram.com/syt.cmp/")
                    }
                  >
                    <img
                      src={instagramIcon}
                      alt="Instagram"
                      width={34}
                      height={34}
                    />
                  </button>
                </p>
              </div>
            )}
            {activeTab === Branch.UK_VIDEO && (
              <div>
                <p>英國視像診症 </p>
                <div className="mt-6 flex w-[100%] justify-center gap-4 lg:justify-start">
                  <div className="">
                    <p>+44 7762 175520 (15:00-18:30 HKT)</p>
                  </div>
                </div>
                <p className="mt-2 flex justify-center">
                  <button
                    onClick={() => window.open("https://wa.me/447762175520")}
                  >
                    <img
                      src={whatsappIcon}
                      alt="WhatsApp"
                      width={34}
                      height={34}
                    />
                  </button>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mx-auto mt-20 flex w-full max-w-[1600px] justify-center pb-5 md:justify-end md:px-20 md:pb-0 xl:mt-0">
        <div className="right-0 flex flex-col gap-8 text-xs md:flex-row md:text-sm">
          <Link to="/info" className="cursor-pointer hover:underline">
            關於我們
          </Link>
          <Link to="/intro" className="cursor-pointer hover:underline">
            醫師介紹
          </Link>
          <Link to="/diary" className="cursor-pointer hover:underline">
            聖賢隨記
          </Link>
          <Link to="/pricing" className="cursor-pointer hover:underline">
            服務及收費
          </Link>
          <Link to="/blogs" className="cursor-pointer hover:underline">
            聖賢中醫錦囊
          </Link>
          <Link to="/charity" className="cursor-pointer hover:underline">
            慈善公益
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
