import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
enum Index {
  Home = 0,
  KWAIFUNG = 1,
  YUENLONG = 2,
  KWUN_TONG = 3,
}
function ArrowLeftOrionIcon() {
  return (
    <svg
      aria-describedby="desc"
      aria-labelledby="title"
      role="img"
      width="18"
      height="18"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Indicator Left</title>
      <desc>A solid styled icon from Orion Icon Library.</desc>
      <path
        d="M25.121 47.121L11.128 33.128l-.012-.012L9 31l16.121-16.121a3 3 0 0 1 4.242 4.242l-8.879 8.88h29.517a3 3 0 1 1 0 6H20.486l8.877 8.878a3 3 0 0 1-4.242 4.242z"
        data-name="layer1"
        fill="white"
      />
    </svg>
  );
}
function Example() {
  const [show, setShow] = useState(false);
  const [index, setIndex] = useState(Index.Home);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function returnTitle() {
    if (index === Index.Home) {
      return (
        <Modal.Title>
          <b>分店資料</b>
        </Modal.Title>
      );
    } else if (index === Index.YUENLONG) {
      return (
        <Modal.Title>
          <b>聖賢堂（元朗）診所</b>
        </Modal.Title>
      );
    } else if (index === Index.KWAIFUNG) {
      return (
        <Modal.Title>
          <b>聖賢堂（葵芳）診所</b>
        </Modal.Title>
      );
    } else if (index === Index.KWUN_TONG) {
      return (
        <Modal.Title>
          <b>聖賢堂（觀塘）診所</b>
        </Modal.Title>
      );
    }
  }

  function returnFooter() {
    if (index === Index.Home) {
      return (
        <>
          <Modal.Footer>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <b>元朗</b>
              <div>
                - 元朗青山公路元朗段(大馬路)六十五號豪景商業大廈二十樓全層
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "1rem",
                }}
              >
                <Button
                  onClick={(e) => {
                    setIndex(Index.YUENLONG);
                  }}
                >
                  如何前往
                </Button>
                <div style={{ display: "flex" }}>
                  <Button
                    className="btn-green"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "0.5rem",
                    }}
                    onClick={() => {
                      window.open("tel:+85224683668");
                    }}
                  >
                    <svg
                      style={{ marginRight: "0.5rem" }}
                      width="18"
                      height="18"
                      viewBox="0 0 202.592 202.592"
                      // fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M198.048,160.105l-31.286-31.29c-6.231-6.206-16.552-6.016-23.001,0.433l-15.761,15.761
			c-0.995-0.551-2.026-1.124-3.11-1.732c-9.953-5.515-23.577-13.074-37.914-27.421C72.599,101.48,65.03,87.834,59.5,77.874
			c-0.587-1.056-1.145-2.072-1.696-3.038l10.579-10.565l5.2-5.207c6.46-6.46,6.639-16.778,0.419-23.001L42.715,4.769
			c-6.216-6.216-16.541-6.027-23.001,0.433l-8.818,8.868l0.243,0.24c-2.956,3.772-5.429,8.124-7.265,12.816
			c-1.696,4.466-2.752,8.729-3.235,12.998c-4.13,34.25,11.52,65.55,53.994,108.028c58.711,58.707,106.027,54.273,108.067,54.055
			c4.449-0.53,8.707-1.593,13.038-3.275c4.652-1.818,9.001-4.284,12.769-7.233l0.193,0.168l8.933-8.747
			C204.079,176.661,204.265,166.343,198.048,160.105z M190.683,176.164l-3.937,3.93l-1.568,1.507
			c-2.469,2.387-6.743,5.74-12.984,8.181c-3.543,1.364-7.036,2.24-10.59,2.663c-0.447,0.043-44.95,3.84-100.029-51.235
			C14.743,94.38,7.238,67.395,10.384,41.259c0.394-3.464,1.263-6.95,2.652-10.593c2.462-6.277,5.812-10.547,8.181-13.02l5.443-5.497
			c2.623-2.63,6.714-2.831,9.112-0.433l31.286,31.286c2.394,2.401,2.205,6.492-0.422,9.13L45.507,73.24l1.95,3.282
			c1.084,1.829,2.23,3.879,3.454,6.106c5.812,10.482,13.764,24.83,29.121,40.173c15.317,15.325,29.644,23.27,40.094,29.067
			c2.258,1.249,4.32,2.398,6.17,3.5l3.289,1.95l21.115-21.122c2.634-2.623,6.739-2.817,9.137-0.426l31.272,31.279
			C193.5,169.446,193.31,173.537,190.683,176.164z"
                        fill="white"
                      ></path>
                    </svg>
                    電話
                  </Button>
                  {/*<Button  className="btn-green"style={{display:"flex", alignItems:"center"}}*/}
                  {/*        onClick={()=>{  window.open("https://wa.me/85263811939");}}*/}
                  {/*>*/}
                  {/*  <svg*/}

                  {/*    width="22"*/}
                  {/*    height="22"*/}
                  {/*    viewBox="0 0 32 32"*/}
                  {/*    fill="green"*/}
                  {/*    xmlns="http://www.w3.org/2000/svg"*/}
                  {/*    style={{backgroundColor:"#4dc247",borderRadius:"50%", marginRight:"0.5rem"}}*/}
                  {/*  >*/}
                  {/*    <path*/}
                  {/*      d="M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z"*/}
                  {/*      fill="white"*/}
                  {/*      fillRule="evenodd"*/}
                  {/*    />*/}
                  {/*  </svg>*/}
                  {/*  Whatsapp*/}
                  {/*</Button>*/}
                </div>
              </div>
            </div>
          </Modal.Footer>
          {/*<Modal.Footer style={{paddingBottom:'1rem'}}>*/}
          {/*  */}
          {/*</Modal.Footer>*/}
        </>
      );
    } else if (index === Index.KWAIFUNG) {
      return (
        <Modal.Footer>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#306166",
              }}
              onClick={(e) => setIndex(Index.Home)}
            >
              {ArrowLeftOrionIcon()}
              上一頁
            </Button>
            <div style={{ display: "flex" }}>
              <Button
                className="btn-green"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  window.open("tel:+85223332025");
                }}
              >
                <svg
                  style={{ marginRight: "0.5rem" }}
                  width="18"
                  height="18"
                  viewBox="0 0 202.592 202.592"
                  // fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M198.048,160.105l-31.286-31.29c-6.231-6.206-16.552-6.016-23.001,0.433l-15.761,15.761
			c-0.995-0.551-2.026-1.124-3.11-1.732c-9.953-5.515-23.577-13.074-37.914-27.421C72.599,101.48,65.03,87.834,59.5,77.874
			c-0.587-1.056-1.145-2.072-1.696-3.038l10.579-10.565l5.2-5.207c6.46-6.46,6.639-16.778,0.419-23.001L42.715,4.769
			c-6.216-6.216-16.541-6.027-23.001,0.433l-8.818,8.868l0.243,0.24c-2.956,3.772-5.429,8.124-7.265,12.816
			c-1.696,4.466-2.752,8.729-3.235,12.998c-4.13,34.25,11.52,65.55,53.994,108.028c58.711,58.707,106.027,54.273,108.067,54.055
			c4.449-0.53,8.707-1.593,13.038-3.275c4.652-1.818,9.001-4.284,12.769-7.233l0.193,0.168l8.933-8.747
			C204.079,176.661,204.265,166.343,198.048,160.105z M190.683,176.164l-3.937,3.93l-1.568,1.507
			c-2.469,2.387-6.743,5.74-12.984,8.181c-3.543,1.364-7.036,2.24-10.59,2.663c-0.447,0.043-44.95,3.84-100.029-51.235
			C14.743,94.38,7.238,67.395,10.384,41.259c0.394-3.464,1.263-6.95,2.652-10.593c2.462-6.277,5.812-10.547,8.181-13.02l5.443-5.497
			c2.623-2.63,6.714-2.831,9.112-0.433l31.286,31.286c2.394,2.401,2.205,6.492-0.422,9.13L45.507,73.24l1.95,3.282
			c1.084,1.829,2.23,3.879,3.454,6.106c5.812,10.482,13.764,24.83,29.121,40.173c15.317,15.325,29.644,23.27,40.094,29.067
			c2.258,1.249,4.32,2.398,6.17,3.5l3.289,1.95l21.115-21.122c2.634-2.623,6.739-2.817,9.137-0.426l31.272,31.279
			C193.5,169.446,193.31,173.537,190.683,176.164z"
                    fill="white"
                  ></path>
                </svg>
                電話
              </Button>
            </div>
          </div>
        </Modal.Footer>
      );
    } else if (index === Index.YUENLONG) {
      return (
        <Modal.Footer>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#306166",
              }}
              onClick={(e) => setIndex(Index.Home)}
            >
              {ArrowLeftOrionIcon()}
              上一頁
            </Button>
            <div style={{ display: "flex" }}>
              <Button
                className="btn-green"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  window.open("tel:+85224683668");
                }}
              >
                <svg
                  style={{ marginRight: "0.5rem" }}
                  width="18"
                  height="18"
                  viewBox="0 0 202.592 202.592"
                  // fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M198.048,160.105l-31.286-31.29c-6.231-6.206-16.552-6.016-23.001,0.433l-15.761,15.761
			c-0.995-0.551-2.026-1.124-3.11-1.732c-9.953-5.515-23.577-13.074-37.914-27.421C72.599,101.48,65.03,87.834,59.5,77.874
			c-0.587-1.056-1.145-2.072-1.696-3.038l10.579-10.565l5.2-5.207c6.46-6.46,6.639-16.778,0.419-23.001L42.715,4.769
			c-6.216-6.216-16.541-6.027-23.001,0.433l-8.818,8.868l0.243,0.24c-2.956,3.772-5.429,8.124-7.265,12.816
			c-1.696,4.466-2.752,8.729-3.235,12.998c-4.13,34.25,11.52,65.55,53.994,108.028c58.711,58.707,106.027,54.273,108.067,54.055
			c4.449-0.53,8.707-1.593,13.038-3.275c4.652-1.818,9.001-4.284,12.769-7.233l0.193,0.168l8.933-8.747
			C204.079,176.661,204.265,166.343,198.048,160.105z M190.683,176.164l-3.937,3.93l-1.568,1.507
			c-2.469,2.387-6.743,5.74-12.984,8.181c-3.543,1.364-7.036,2.24-10.59,2.663c-0.447,0.043-44.95,3.84-100.029-51.235
			C14.743,94.38,7.238,67.395,10.384,41.259c0.394-3.464,1.263-6.95,2.652-10.593c2.462-6.277,5.812-10.547,8.181-13.02l5.443-5.497
			c2.623-2.63,6.714-2.831,9.112-0.433l31.286,31.286c2.394,2.401,2.205,6.492-0.422,9.13L45.507,73.24l1.95,3.282
			c1.084,1.829,2.23,3.879,3.454,6.106c5.812,10.482,13.764,24.83,29.121,40.173c15.317,15.325,29.644,23.27,40.094,29.067
			c2.258,1.249,4.32,2.398,6.17,3.5l3.289,1.95l21.115-21.122c2.634-2.623,6.739-2.817,9.137-0.426l31.272,31.279
			C193.5,169.446,193.31,173.537,190.683,176.164z"
                    fill="white"
                  ></path>
                </svg>
                電話
              </Button>
              {/*<Button className="btn-green" style={{display:"flex", alignItems:"center"}}*/}
              {/*        onClick={()=>{  window.open("https://wa.me/85263811939");}}*/}
              {/*>*/}
              {/*  <svg*/}

              {/*    width="22"*/}
              {/*    height="22"*/}
              {/*    viewBox="0 0 32 32"*/}
              {/*    fill="green"*/}
              {/*    xmlns="http://www.w3.org/2000/svg"*/}
              {/*    style={{backgroundColor:"#4dc247",borderRadius:"50%", marginRight:"0.5rem"}}*/}
              {/*  >*/}
              {/*    <path*/}
              {/*      d="M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z"*/}
              {/*      fill="white"*/}
              {/*      fillRule="evenodd"*/}
              {/*    />*/}
              {/*  </svg>*/}
              {/*  Whatsapp*/}
              {/*</Button>*/}
            </div>
          </div>
        </Modal.Footer>
      );
    } else if (index === Index.KWUN_TONG) {
      return (
        <Modal.Footer>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#306166",
              }}
              onClick={(e) => setIndex(Index.Home)}
            >
              {ArrowLeftOrionIcon()}
              上一頁
            </Button>
            <div style={{ display: "flex" }}>
              <Button
                className="btn-green"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  window.open("tel:+85223236992");
                }}
              >
                <svg
                  style={{ marginRight: "0.5rem" }}
                  width="18"
                  height="18"
                  viewBox="0 0 202.592 202.592"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M198.048,160.105l-31.286-31.29c-6.231-6.206-16.552-6.016-23.001,0.433l-15.761,15.761
			c-0.995-0.551-2.026-1.124-3.11-1.732c-9.953-5.515-23.577-13.074-37.914-27.421C72.599,101.48,65.03,87.834,59.5,77.874
			c-0.587-1.056-1.145-2.072-1.696-3.038l10.579-10.565l5.2-5.207c6.46-6.46,6.639-16.778,0.419-23.001L42.715,4.769
			c-6.216-6.216-16.541-6.027-23.001,0.433l-8.818,8.868l0.243,0.24c-2.956,3.772-5.429,8.124-7.265,12.816
			c-1.696,4.466-2.752,8.729-3.235,12.998c-4.13,34.25,11.52,65.55,53.994,108.028c58.711,58.707,106.027,54.273,108.067,54.055
			c4.449-0.53,8.707-1.593,13.038-3.275c4.652-1.818,9.001-4.284,12.769-7.233l0.193,0.168l8.933-8.747
			C204.079,176.661,204.265,166.343,198.048,160.105z M190.683,176.164l-3.937,3.93l-1.568,1.507
			c-2.469,2.387-6.743,5.74-12.984,8.181c-3.543,1.364-7.036,2.24-10.59,2.663c-0.447,0.043-44.95,3.84-100.029-51.235
			C14.743,94.38,7.238,67.395,10.384,41.259c0.394-3.464,1.263-6.95,2.652-10.593c2.462-6.277,5.812-10.547,8.181-13.02l5.443-5.497
			c2.623-2.63,6.714-2.831,9.112-0.433l31.286,31.286c2.394,2.401,2.205,6.492-0.422,9.13L45.507,73.24l1.95,3.282
			c1.084,1.829,2.23,3.879,3.454,6.106c5.812,10.482,13.764,24.83,29.121,40.173c15.317,15.325,29.644,23.27,40.094,29.067
			c2.258,1.249,4.32,2.398,6.17,3.5l3.289,1.95l21.115-21.122c2.634-2.623,6.739-2.817,9.137-0.426l31.272,31.279
			C193.5,169.446,193.31,173.537,190.683,176.164z"
                    fill="white"
                  ></path>
                </svg>
                電話
              </Button>
            </div>
          </div>
        </Modal.Footer>
      );
    }
  }
  function returnFooter2() {
    if (index === Index.Home) {
      return (
        <>
          <Modal.Footer>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <b>觀塘</b>
              <div>
                - 觀塘觀塘道418號創紀之城5期-東亞銀行中心16樓08室(APM樓上)
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "1rem",
                }}
              >
                <Button
                  onClick={(e) => {
                    setIndex(Index.KWUN_TONG);
                  }}
                >
                  如何前往
                </Button>
                <div style={{ display: "flex" }}>
                  <Button
                    className="btn-green"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "0.5rem",
                    }}
                    onClick={() => {
                      window.open("tel:+85223236992");
                    }}
                  >
                    <svg
                      style={{ marginRight: "0.5rem" }}
                      width="18"
                      height="18"
                      viewBox="0 0 202.592 202.592"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M198.048,160.105l-31.286-31.29c-6.231-6.206-16.552-6.016-23.001,0.433l-15.761,15.761
			c-0.995-0.551-2.026-1.124-3.11-1.732c-9.953-5.515-23.577-13.074-37.914-27.421C72.599,101.48,65.03,87.834,59.5,77.874
			c-0.587-1.056-1.145-2.072-1.696-3.038l10.579-10.565l5.2-5.207c6.46-6.46,6.639-16.778,0.419-23.001L42.715,4.769
			c-6.216-6.216-16.541-6.027-23.001,0.433l-8.818,8.868l0.243,0.24c-2.956,3.772-5.429,8.124-7.265,12.816
			c-1.696,4.466-2.752,8.729-3.235,12.998c-4.13,34.25,11.52,65.55,53.994,108.028c58.711,58.707,106.027,54.273,108.067,54.055
			c4.449-0.53,8.707-1.593,13.038-3.275c4.652-1.818,9.001-4.284,12.769-7.233l0.193,0.168l8.933-8.747
			C204.079,176.661,204.265,166.343,198.048,160.105z M190.683,176.164l-3.937,3.93l-1.568,1.507
			c-2.469,2.387-6.743,5.74-12.984,8.181c-3.543,1.364-7.036,2.24-10.59,2.663c-0.447,0.043-44.95,3.84-100.029-51.235
			C14.743,94.38,7.238,67.395,10.384,41.259c0.394-3.464,1.263-6.95,2.652-10.593c2.462-6.277,5.812-10.547,8.181-13.02l5.443-5.497
			c2.623-2.63,6.714-2.831,9.112-0.433l31.286,31.286c2.394,2.401,2.205,6.492-0.422,9.13L45.507,73.24l1.95,3.282"
                        fill="white"
                      ></path>
                    </svg>
                    電話
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Footer>
        </>
      );
    }   
  }
  function returnBody() {
    if (index === Index.Home) {
      return (
        <Modal.Body>
          <div
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            <b>葵芳</b>
            <div>- 新界葵芳興芳路223號新都會廣場一期21樓 2113-2114室</div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "1rem",
              }}
            >
              <Button onClick={(e) => setIndex(Index.KWAIFUNG)}>
                如何前往
              </Button>
              <div style={{ display: "flex" }}>
                <Button
                  className="btn-green"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "0.5rem",
                  }}
                  onClick={() => {
                    window.open("tel:+85223332025");
                  }}
                >
                  <svg
                    style={{ marginRight: "0.5rem" }}
                    width="18"
                    height="18"
                    viewBox="0 0 202.592 202.592"
                    // fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M198.048,160.105l-31.286-31.29c-6.231-6.206-16.552-6.016-23.001,0.433l-15.761,15.761
			c-0.995-0.551-2.026-1.124-3.11-1.732c-9.953-5.515-23.577-13.074-37.914-27.421C72.599,101.48,65.03,87.834,59.5,77.874
			c-0.587-1.056-1.145-2.072-1.696-3.038l10.579-10.565l5.2-5.207c6.46-6.46,6.639-16.778,0.419-23.001L42.715,4.769
			c-6.216-6.216-16.541-6.027-23.001,0.433l-8.818,8.868l0.243,0.24c-2.956,3.772-5.429,8.124-7.265,12.816
			c-1.696,4.466-2.752,8.729-3.235,12.998c-4.13,34.25,11.52,65.55,53.994,108.028c58.711,58.707,106.027,54.273,108.067,54.055
			c4.449-0.53,8.707-1.593,13.038-3.275c4.652-1.818,9.001-4.284,12.769-7.233l0.193,0.168l8.933-8.747
			C204.079,176.661,204.265,166.343,198.048,160.105z M190.683,176.164l-3.937,3.93l-1.568,1.507
			c-2.469,2.387-6.743,5.74-12.984,8.181c-3.543,1.364-7.036,2.24-10.59,2.663c-0.447,0.043-44.95,3.84-100.029-51.235
			C14.743,94.38,7.238,67.395,10.384,41.259c0.394-3.464,1.263-6.95,2.652-10.593c2.462-6.277,5.812-10.547,8.181-13.02l5.443-5.497
			c2.623-2.63,6.714-2.831,9.112-0.433l31.286,31.286c2.394,2.401,2.205,6.492-0.422,9.13L45.507,73.24l1.95,3.282
			c1.084,1.829,2.23,3.879,3.454,6.106c5.812,10.482,13.764,24.83,29.121,40.173c15.317,15.325,29.644,23.27,40.094,29.067
			c2.258,1.249,4.32,2.398,6.17,3.5l3.289,1.95l21.115-21.122c2.634-2.623,6.739-2.817,9.137-0.426l31.272,31.279
			C193.5,169.446,193.31,173.537,190.683,176.164z"
                      fill="white"
                    ></path>
                  </svg>
                  電話
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      );
    } else if (index === Index.KWAIFUNG) {
      return (
        <Modal.Body>
          <b>地址：</b>
          <h6>新界葵芳興芳路223號新都會廣場一期21樓2113-2114室</h6>
          <p>
            <a className="tel" style={{ color: "green" }} href="tel:23332025">
              23332025
            </a>
          </p>
          <div style={{ marginTop: "2rem" }}>
            <b>前往方法：</b>
            <h6>
              葵芳站E出口直行至新都會廣場﹐乘電梯到二樓經奇華餅家側通道進入新都會廣場一期電梯大堂便到達。
            </h6>
          </div>
          <p style={{ marginTop: "2rem" }}>
            點擊地圖以使用 Google 地圖進行導航
          </p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3689.884330250841!2d114.12670299999999!3d22.357996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3403f9238a9e6265%3A0x8e3237be536203e9!2z5b-g6Yar6Ki65omAIOiBluizouWggg!5e0!3m2!1szh-TW!2shk!4v1679752074702!5m2!1szh-TW!2shk"
              width="450"
              height="350"
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </Modal.Body>
      );

      // 立即預約
    } else if (index === Index.YUENLONG) {
      return (
        <Modal.Body>
          <b>地址：</b>
          <h6>元朗青山公路元朗段六十五號豪景商業大廈二十樓全層</h6>
          <p>
            <a className="tel" style={{ color: "green" }} href="tel:24683668">
              24683668
            </a>
          </p>
          <div style={{ marginTop: "2rem" }}>
            <b>前往方法：</b>
            <p style={{ fontSize: "14px", marginBottom: "1rem" }}>
              1,
              搭乘輕鐵到大棠路站，落車後先找到東亞銀行，到達銀行後，面向銀行右轉，步行兩分鐘即可到達
            </p>
            <p style={{ fontSize: "14px", marginBottom: "1rem" }}>
              2,
              搭乘綠色小巴：31、32、36、37、38、79S、609、609B，到谷亭街站下車，豪景商業大廈便在759阿信屋、德善堂涼茶店隔壁
            </p>
            <p style={{ fontSize: "14px", marginBottom: "1rem" }}>
              3, 搭乘巴士：53、64X、276、276P
              於谷亭街站下車，豪景商業大廈便在759阿信屋、德善堂涼茶店隔壁
            </p>
          </div>
          <p style={{ marginTop: "2rem" }}>
            點擊地圖以使用 Google 地圖進行導航
          </p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3687.584981250768!2d114.03005479999999!3d22.4446413!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3403f14386153931%3A0xe72e62f9bc7dd14b!2z5b-g6Yar6Ki65omAIOiBluizouWggg!5e0!3m2!1szh-TW!2shk!4v1679752108602!5m2!1szh-TW!2shk"
              width="600"
              height="450"
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </Modal.Body>
      );
    } else if (index === Index.KWUN_TONG) {
      return (
        <Modal.Body>
          <b>地址：</b>
          <h6>觀塘觀塘道418號創紀之城5期-東亞銀行中心16樓08室(APM樓上)</h6>
          <p>
            <a className="tel" style={{ color: "green" }} href="tel:23236992">
              23236992
            </a>
          </p>
          <div style={{ marginTop: "2rem" }}>
            <b>前往方法：</b>
            <p style={{ fontSize: "14px", marginBottom: "1rem" }}>
              1, 港鐵觀塘線觀塘站B1出口，步行約3分鐘即可到達
            </p>
            <p style={{ fontSize: "14px", marginBottom: "1rem" }}>
              2, 搭乘巴士：11D、23、74X、80、80X、83X、93A、268C、269C 於觀塘站下車
            </p>
          </div>
          <p style={{ marginTop: "2rem" }}>
            點擊地圖以使用 Google 地圖進行導航
          </p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3691.1675811137287!2d114.22324571495943!3d22.31198048531783!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34040146c53f4d0f%3A0x11e5c0932f4b501e!2z5b-g6Yar6Ki65omAIOimluWhnuWggg!5e0!3m2!1szh-TW!2shk!4v1679752108602!5m2!1szh-TW!2shk"
              width="600"
              height="450"
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </Modal.Body>
      );
    }
  }
  return (
    <>
      <div
        onClick={(x) => {
          handleShow();
        }}
        className="float flex h-fit cursor-pointer flex-col items-center justify-center border
         border-[#306166] bg-[#306166] text-sm text-white md:h-[80px]
         md:w-[80px] md:text-base
         "
      >
        <p>預約</p>
        <p>診症</p>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>{returnTitle()}</Modal.Header>
        {returnBody()}
        {returnFooter()}
        {returnFooter2()}

        {index === Index.Home ? (
          <Modal.Footer style={{ borderBottom: "0px" }}>
            {/* <button className="btn-booking">立即預約</button> */}
            <Link to="/booking" className="btn-booking" onClick={handleClose}>
              立即預約
            </Link>
          </Modal.Footer>
        ) : (
          <Modal.Footer></Modal.Footer>
        )}
      </Modal>
    </>
  );
}

export default Example;
