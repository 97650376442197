export enum Sex {
  Male = "男",
  Female = "女",
  Other = "其他",
}

export enum Branch {
  YUEN_LONG = "元朗",
  KWAI_FONG = "葵芳",
  KWUN_TONG = "觀塘",
  UK_VIDEO = "英國視像診症",
}

export enum ContactMethod {
  Whatsapp = "Whatsapp",
  Telegram = "Telegram",
  Signal = "Signal",
}

export enum Doctor {
  徐 = "徐宇聖醫師",
  鄧 = "鄧敏賢醫師",
  羅 = "羅顯耀醫師 ",
  洪 = "洪嬿醫師",
  潘 = "潘醫師",
  王 = "王康妮醫師",
  郭 = "郭思伶醫師",
  余 = "余曉燕醫師",
  江 = "江詠欣醫師",
  鄭 = "鄭梓健醫師",
  // 魏 = '魏芷晴醫師',
}

export enum Weekday {
  Monday = "一",
  Tuesday = "二",
  Wednesday = "三",
  Thursday = "四",
  Friday = "五",
  Saturday = "六",
  Sunday = "日",
}
